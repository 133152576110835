import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import UserIcon from "@material-ui/icons/Person";
import ShoppingCartOutlined from "@material-ui/icons/ShoppingCartOutlined";
import Cookies from 'js-cookie';
import ModalAccion from '../Modales/ModalAccion.jsx';
import Globales from "utils/Globales";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";
import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.jsx";
import LoginButton from "../../Aunt0/loginbutton.js";
import LogoutButton from "../../Aunt0/logoutbutton.js";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import { CircularProgress, Badge } from '@material-ui/core';
import { set } from "mongoose";
function HeaderLinks(props) {
  const { classes, dropdownHoverColor } = props;
  const { isAuthenticated, user,isLoading,logout } = useAuth0();
  const [auth, setAuth] = useState(null);
  const [liveDemo, setLiveDemo] = useState(false);
  const [logeado, setlogeado] = useState(false);
  const [cargando, setCargando] = useState(true);
  const [Usuario, setUsuario] = useState("Usuario");

  const [colorNotificacion, setColorNotificacion] = useState("success");
  const [mensaje, setMensaje] = useState("");
  const [br, setBr] = useState(false);
  const showNotification = (mensaje, color) => {
    setBr(true);
    setMensaje(mensaje);
    setColorNotificacion(color);
    setTimeout(
      function () {
        setBr(false);
      }.bind(this),
      3000
    );
  }

  useEffect(() => {
    let mensaje = Cookies.get("Mensaje");
    if (mensaje != null) {
      let colorMensaje = Cookies.get("ColorMensaje");
      showNotification(mensaje, colorMensaje);
      Cookies.remove("mensaje");
    }

    let sesion = Cookies.get("UsuarioTerco")
    console.log(sesion)
    
    if (sesion && JSON.parse(sesion).id != "") {
      sesion = JSON.parse(sesion);
      console.log(sesion);
      Login(sesion.RedSocial);
    }
  }, []);

  const handleClickOpen = (modal) => {
    setLiveDemo({ [modal]: true });
  };

  const handleClose = (modal) => {
    setLiveDemo({ [modal]: false });
  };

  const openModal = () => {
    setLiveDemo(true);
  };

  const irPerfil = () => {
    window.location.href = '/#/Perfil';
    window.location.reload();
  };

  const eventoModal = (e) => {
    if (!e) {
      setLiveDemo(false);
      return;
    }
    cerrarSesion();
  };

  useEffect(() => {
    if(cargando == true){
      if (isLoading) {
        // ya no esta cargando
        setCargando(true);
      } else if (isAuthenticated) {
        // Llama a la función Login cuando el usuario esté autenticado y se haya cargado
        ValidarUsuario(user);
      }else{
        // ya no esta cargando
        setCargando(false);
      }
    } 
  }, [isAuthenticated, user, isLoading]);

  const Login = (RedSocial) => {
    setCargando(true);
    fetch(Globales.Url + "IniciarSesionCliente", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token
      },
      body: JSON.stringify({
        RedSocial      
      })
    })
      .then(res => res.json())
      .then(data => {
        if (data.status !== 404) {
          // Actualiza el estado con la respuesta del servidor
          setCargando(true);
          setAuth(data);
          if (data.response === true) {
            var sesion = {
              id: data.usuario._id,
              RedSocial: RedSocial,
              Carrito: data.carrito,
              ListaCarrito: data.usuario.ListaCarrito,
              ListaDeseos: data.usuario.ListaDeseos,
              Usuario: data.usuario.Usuario,
              Nombre: data.usuario.Nombre,
              Correo: data.usuario.Correo,
              Telefono: data.usuario.Telefono,
              TipoUsuario: data.usuario.TipoUsuario,
              Token: data.token,
              IndicadorTerco: data.usuario.IndicadorTerco,
            };
            setlogeado(true);
            setUsuario(data.usuario.Nombre);
            setCargando(false);
            // Cookies.set("UsuarioTerco", JSON.stringify(sesion));
            Cookies.set("UsuarioTerco", JSON.stringify(sesion), { expires: 7 });
            Cookies.set("Id", data.usuario._id);
            Cookies.set("token", data.token);
            localStorage.setItem("idUser", data.usuario._id);
            Globales.token = "Bearer " + data.token;
            let dt = new Date();
            localStorage.setItem(
              "expira",
              new Date(dt.getTime() + 20 * 60000)
            );
            if (Cookies.get("idDiseno") != null) {
              Cookies.set("login", 1);
              // window.opener.top.location.reload();
              // window.close();
            }
            // AumentarVidaCookies()
          }else{
            logout()
            let sesion = {
              id: '',
              Carrito: "",
              ListaCarrito: [],
              ListaDeseos: [],
              Usuario: "",
              Nombre: "",
              Usuario: "",
              Correo: "",
              Telefono: "",
              TipoUsuario: "",
              token: "",
              RedSocial: "",
              IndicadorTerco: "",
            }
            setlogeado(false);
            setUsuario("");
            setCargando(false);
            Cookies.set("UsuarioTerco", JSON.stringify(sesion), { expires: 7 });
            showNotification(data.mensaje, "warning")
          }
        }
      })
      .catch(error => {
        console.error("Error:", error);
      });
  };

  const RegistrarUsuario = (usuarioNuevo) => {
    fetch(Globales.Url + "UsuarioClientes/UsuarioClientes/RegistrarCliente", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify(usuarioNuevo)
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Error en la solicitud');
        }
        return res.json();
      })
      .then((data) => {
        if (data.response === true) {
          Login(user.sub); 
          window.location.reload();

        } else {
          console.error('Error en el registro:', data.message); // si hay un mensaje de error en la respuesta
        }
      })
      .catch((err) => console.error(err));
  };
 
  const ValidarUsuario = (user) =>
  {
    fetch(
      Globales.Url + "UsuarioClientes/UsuarioClientes/ValidarUsuarioPorSocialNetwork",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: Globales.token,
        },
        body: JSON.stringify({ idTokenSocialNetwork: user.sub,Correo:user.email }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.data != null) {
          Login(user.sub);
        }else{
          logout({ logoutParams: { returnTo: window.location.origin } })
          showNotification(data.mensaje, "warning")
          setCargando(false)
        }
      })
      .catch((err) => console.error(err));
  };
  const AumentarVidaCookies = () => {
    const cookies = document.cookie.split(";");
  
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      let expires = new Date();
      expires.setFullYear(expires.getFullYear() + 1);
      document.cookie = name + "=;expires=" + expires.toUTCString() + ";path=/";
      // document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
  }
  
  const cerrarSesion = () => {
    setLiveDemo(false);
    var sesion = {
      id: '',
      Carrito: "",
      ListaCarrito: [],
      ListaDeseos: [],
      Usuario: "",
      Nombre: "",
      Usuario: "",
      Correo: "",
      Telefono: "",
      TipoUsuario: "",
      token: "",
      RedSocial: "",
      IndicadorTerco: "",
    }
    Cookies.set("UsuarioTerco", JSON.stringify(sesion))
    Cookies.set("Id", "")
    localStorage.setItem("idUser", "")
    Globales.token = '';
  }

  const CambiarRuta = (ruta) => {
    window.location.href = ruta;
  }

  const popover = {
    position: 'absolute',
    right: '0',
  }

  const cantidadCarrito = Cookies.get("UsuarioTerco") && JSON.parse(Cookies.get("UsuarioTerco")).id != "" ? JSON.parse(Cookies.get("UsuarioTerco")).Carrito : 0
  
  return (
    <div style={popover}>
      <List className={classes.list + " " + classes.mlAuto} style={{ fontSize: "12px" }}>
        <ListItem className={classes.listItem}>
          <Link to="/" className={classes.dropdownLink} >INICIO</Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/SeleccionaTuDiseño" className={classes.dropdownLink} >DISEÑADOR</Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/Artistas" className={classes.dropdownLink} >ARTISTAS</Link>
        </ListItem>
        {
          cargando ?
          <ListItem className={classes.listItem}>
            <CircularProgress size={35} thickness={5} style={{ color: "white" }} /> 
          </ListItem>
            :
          logeado ?
            <ListItem className={classes.listItem}>
              <CustomDropdown
                noLiPadding
                navDropdown
                // hoverColor={dropdownHoverColor}
                buttonText={Usuario}
                // buttonText={user.name}
                buttonProps={{
                  style: {
                    border: "1px solid #fefefe",
                    borderRadius: 5,
                    fontSize: 12,
                  },
                  color: "transparent",
                }}
                dropdownList={[
                  <Link to="#" onClick={() => irPerfil()} className={classes.dropdownLink} >
                    PERFIL
                  </Link>,
                  <LogoutButton />
                ]}
              />
            </ListItem>
            :
            <ListItem style={{ width: "135px" }} className={classes.listItem}>
              <LoginButton />
            </ListItem>
        }
        {
          Cookies.get("UsuarioTerco") && JSON.parse(Cookies.get("UsuarioTerco")).id != "" ?
            <ListItem>
              <Link className={classes.dropdownLink} to="/carrito">
                {
                  window.innerWidth < 960 ? "Carrito " + "(" + cantidadCarrito + ")" :
                    <Badge badgeContent={cantidadCarrito} >
                      <div style={{ backgroundColor: "212121", color: "#fefefe", }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-cart3" viewBox="0 0 16 16">
                          <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l.84 4.479 9.144-.459L13.89 4zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                        </svg>
                      </div>
                    </Badge>
                }
              </Link>
            </ListItem>
            : null
        }
      </List>
      <div id="">
        <Snackbar
          place="tr"
          color={colorNotificacion}
          message={mensaje}
          open={br}
          closeNotification={() => setBr(false)}
          close
        />
        <ModalAccion
          Titulo='Cerrar Sesión'
          body={'¿Seguro que deseas cerrar sesión?'}
          liveDemo={liveDemo}
          eventClick={eventoModal}
        />
      </div>
    </div>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};

export default withStyles(headerLinksStyle)(HeaderLinks);