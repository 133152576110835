import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
// sections for this page
import Header from "../Generales/Header/Header.jsx";
import HeaderLinks from "../Generales/Header/HeaderLinks.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Globales from "utils/Globales";
import ModalArtista from "../Generales/Modales/ModalTiendaArtista";
import Loader from "react-loader-spinner";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Pagination from "@material-ui/lab/Pagination";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle.jsx";
import UsuarioBlanco from "assets/img/faces/UsuarioBlanco1.png";

class EcommercePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Cargando: true,
      Artistas: [],
      DatosArtista: {},
      VerModalArtista: false,
      currentPage: 1,
      itemsPerPage: 15,
      totalArtistas: 0
    };
    this.EventoModal = this.EventoModal.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    await this.ObtenerArtistasPaginado(this.state.currentPage);
  }
  async componentDidUpdate(prevProps, prevState) {
    if (prevState.currentPage !== this.state.currentPage) {
      await this.ObtenerArtistasPaginado(this.state.currentPage);
    }
  }

  async ObtenerArtistasPaginado(pagina) {
    const porPagina = this.state.itemsPerPage;
    try {
      const response = await fetch(
        Globales.Url +
          "TiendaArtistas/TiendaArtistas/ObtenerAristasDisenosMasVendidosPaginado",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: Globales.token
          },
          body: JSON.stringify({ pagina, porPagina, Todos: true })
        }
      );

      if (response.ok) {
        const data = await response.json();
        //console.log("este es el filtrado de artistas")
        //console.log(data)
        this.setState({
          Artistas: data.data,
          totalArtistas: data.total,
          Cargando: false
        });
      } else {
        console.error("Error al obtener artistas:", response.status);
      }
    } catch (error) {
      console.error("Error al obtener artistas:", error);
    }
  }

  handlePageChange(event, newPage) {
    this.setState({ currentPage: newPage, Cargando: true }, () => {
      this.ObtenerArtistasPaginado(newPage);
    });
  }

  EventoModal(e) {
    this.setState({
      VerModalArtista: false
    });
  }

  render() {
    const { classes } = this.props;
    const { currentPage, itemsPerPage, totalArtistas } = this.state;
    // const indexOfLastArtist = currentPage * itemsPerPage;
    // const indexOfFirstArtist = indexOfLastArtist - itemsPerPage;
    const currentArtists = this.state.Artistas;

    return (
      <div>
        <Header
          links={<HeaderLinks dropdownHoverColor="dark" />}
          fixed
          color="dark"
        />
        <Parallax
          image={require("assets/img/terco-img/BannerTodosArtistas.jpg")}
          filter="dark"
          style={{ height: "300px" }}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                md={10}
                sm={10}
                className={classNames(
                  classes.mlAuto,
                  classes.mrAuto,
                  classes.textCenter
                )}
              >
                <div className={classes.brand}>
                  <h1 className={classes.title}>Artistas</h1>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div
          className={classNames(classes.main, classes.mainRaisedNotShadow)}
          style={{ backgroundColor: "transparent"}}
        >
          {this.state.Cargando === false ? (
            <div>
              <GridContainer
                // justify="center"
                style={{
                  marginRight: "50px",
                  marginLeft: "50px",
                  width: "auto",
                  marginBottom: "10px",
                  paddingBottom: "50px"
                }}
              >
                {currentArtists.map(m => (
                  // eslint-disable-next-line react/jsx-key
                  <GridItem
                    lg={4}
                    md={4}
                    sm={6}
                    xs={12}
                    style={{ height: "150px", paddingBottom: "60px" }}
                  >
                    <Card
                      onClick={() =>
                        (window.location.href = `#/Tienda/${m._id}`)
                      }
                      style={{
                        cursor: "pointer"
                      }}
                    >
                      <CardBody>
                        <div
                          className="row"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            textAlign: "left"
                          }}
                        >
                          <div
                            lg={4}
                            md={4}
                            sm={4}
                            xs={4}
                            style={{ marginRight: "25px" }}
                          >
                            <img
                              src={
                                m.LogoImg == ""
                                  ? UsuarioBlanco
                                  : "data:image/png;base64," + m.LogoImg
                              }
                              style={{
                                width: "80px",
                                height: "80px",
                                objectFit: "cover",
                                borderRadius: "50%",
                                boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)"
                              }}
                            />
                          </div>
                          <div
                            lg={8}
                            md={8}
                            sm={8}
                            xs={8}
                            style={{ marginRight: "25px" }}
                          >
                            <p style={{ fontSize: "18px" }}>
                              <b>{m.Nombre}</b>
                            </p>
                            <p>{m.totalVendidos} Diseños vendidos</p>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </GridItem>
                ))}
              </GridContainer>
              <div
                className={classes.pagination}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "20px"
                }}
              >
                <Pagination
                  count={Math.ceil(totalArtistas / itemsPerPage)}
                  page={currentPage}
                  onChange={this.handlePageChange}
                  color="standard"
                  size="large"
                  showFirstButton
                  showLastButton
                  style={{
                    marginBottom: "20px"
                  }}
                />
              </div>
            </div>
          ) : (
            <div>
              <center>
                <h2>Cargando artistas</h2>
                <Loader
                  visible={true}
                  type="Oval"
                  color="#212121"
                  height={40}
                  width={40}
                />
              </center>
            </div>
          )}

          {this.state.VerModalArtista == true ? (
            <ModalArtista
              verModal={this.state.VerModalArtista}
              eventoModal={this.EventoModal}
              DatosArtista={this.state.DatosArtista}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(EcommercePage);
